<template>
  <HeaderSection v-bind="headerProps" />
  <SocialBox v-bind="socialProps" />
  <section class="hero-media">
    <Carousel :items="mediaInfo" />
  </section>

  <div class="content-wrap">
    <div class="entry entry-page">
      <h2 class="entry-title">Request received</h2>
      <div class="entry-content">
        <p class="mb-7">Sent your message successfully!</p>
      </div>
    </div>
    <CopyRight v-bind="footerProps" />
  </div>
</template>

<script>
import HeaderSection from "@/components/organisms/Header/Header.vue";
import SocialBox from "@/components/molecules/SocialBox/SocialBox.vue";
import CopyRight from "@/components/molecules/CopyRight/CopyRight.vue";
import { headerProps, socialProps, footerProps } from "@/components/constants";
import Carousel from "@/components/organisms/Carousel/Carousel.vue";

/**
 *
 */
export default {
  components: {
    HeaderSection,
    SocialBox,
    CopyRight,
    Carousel,
  },
  data() {
    return {
      headerProps: { ...headerProps, currentRoute: this.$route.name },
      socialProps: socialProps,
      mediaInfo: [
        {
          image:
            "https://starlight-media.s3.us-west-2.amazonaws.com/PHOTOSHOOTS/APRIL+2023+-+CHELLSEA+ELLE/Adamari.png",
        },
      ],
      footerProps,
    };
  },
  beforeCreate() {
    // this.$nextTick().then(() => {
    document.body.classList.add("contact");
    document.body.classList.remove("sb-main-padded");
    // })
  },
};
</script>
<style></style>
